<template>
  <div class="m-5">
    <v-row>
      <v-col
        v-for="i in 12"
        :key="i"
        cols="12"
        lg="3"
        md="4"
        sm="6"
        class=""
      > 
        <div class="w-full">
          <v-skeleton-loader
            rounded="0"
            type="image, heading, text, button"
            class="h-100 justify-center p-4 semitransparent"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.semitransparent {
  background-color: rgba(255,255,255,0.5);
  
}
</style>