<template>
  <v-navigation-drawer
    elevation="5"
    permanent
    class=""
  >
    <div class="bg-base-100 justify-space-between text-center h-full">
      <NuxtLink to="/">
        <template v-if="useRuntimeConfig().public.SHOP_NAME=='LSDOPE'">
          <br>
          <p class="text-6xl header_sitetitle drop-shadow-lg text-secondary">
            LSDope
          </p>
        </template>
        <template v-if="useRuntimeConfig().public.SHOP_NAME=='NyxKnacks'">
          <p class="ml-4 pt-6 text-5xl text-left header_sitetitle drop-shadow">
            Nyx
          </p>
          <p class="mr-5 pb-2 text-5xl text-right header_sitetitle drop-shadow">
            Knacks
          </p>
        </template>
        <br>
      </NuxtLink>
      <ClientOnly>
        <v-item-group
          v-model="currTab"
          class="bg-secondary glass"
        >
          <template
            v-for="(top, idx) in tabs"
            :key="idx"
          >
            <NuxtLink v-if="top!=undefined" :to="'/shop/products/'+encodeURIComponent(top)">
              <button
                class="w-full text-xl py-2 rounded-0"
              >
                <p class="hover:font-bold">
                  {{ top }}
                </p>
              </button>
            </NuxtLink>
          </template>
        </v-item-group>
        <template #fallback>
          <p>Loading Sidebar</p>
        </template>
      </ClientOnly>
      <div class="menu text-center absolute bottom-2 w-full text-base-content text-lg gap-1">
        <DevOnly>
          <button
            class="hover:font-bold"
            @click="devmenudrawer = !devmenudrawer"
          >
            Dev Menu
          </button>
        </DevOnly>
        <!-- <NuxtLink
          to="/search"
          no-prefetch
        >
          <button class="hover:font-bold">
            Search
          </button>
        </NuxtLink> -->
        <button
          class="hover:font-bold"
          @click="wishdrawer = !wishdrawer"
        >
          Wish List
        </button>
        <NuxtLink to="/checkout">
          <button class="hover:font-bold">
            View Cart
          </button>
        </NuxtLink>
        <NuxtLink to="/account">
          <button class="hover:font-bold">
            My Account
          </button>
        </NuxtLink>
        <button
          class="hover:font-bold"
          @click="themedrawer = !themedrawer"
        >
          Change Site Colors
        </button>
      </div>
    </div>
  </v-navigation-drawer>
  <!--Dev Menu Drawer-->
  <DevOnly>
    <v-navigation-drawer
      v-model="devmenudrawer"
      temporary
    >
      <ul class="menu text-center min-h-full bg-base-100 text-base-content">
        <br>
        <h1>Dev Menu</h1>
        <br>
        <div class="flex flex-col gap-2">
          <button
            class="btn bg-primary glass"
            @click="makeMeAdmin()"
          >
            New Admin
          </button>
          <button
            class="btn bg-primary glass"
            @click="fetchProducts()"
          >
            Products
          </button>
          <button class="btn bg-primary glass">
            !Test CS Case*
          </button>
          <button class="btn glass bg-primary">
            <NuxtLink to="/ash">
              Ash
            </NuxtLink>
          </button>
        </div>
      </ul>
    </v-navigation-drawer>
  </DevOnly>
  <v-navigation-drawer
    v-model="themedrawer"
    temporary
  >
    <ToggleTheme />
  </v-navigation-drawer>
  <v-navigation-drawer
    v-model="wishdrawer"
    temporary
  >
    <Wish />
  </v-navigation-drawer>
</template>

<script setup>
import { useCurrentUser } from 'vuefire';
const currTab=ref('All')
// const shopdrawer=ref()
const wishdrawer=ref()
const devmenudrawer=ref()
const themedrawer = ref(null)


const fetchProducts = async() => {
  if(useCurrentUser().value){
    await $fetch("/api/printify/refresh-printify-products", { method:"POST", headers:{token:(await useCurrentUser().value.getIdToken())} }) //should i await this? //yes otherwise our tag sidebar doesn't update
  } else {
    if(useRuntimeConfig().public.WTFAMI=="DEV"){
      //This will fail on prod if no user token is passed
      await $fetch("/api/printify/refresh-printify-products", { method:"POST"})
    } else{
      return {error: "Unauthorized"}
    }
  }
}

const tabs = ref(['All'])
tabs.value = [
  'All',
  'Candles',
  'Mugs',
  'Puzzles',
  'Journals',
]

//Dynamic Tabs
// const tabs = ref(['All'])
// const productsRef = collection(useFirestore(),'products')
// //Could do a orderBy(__name__,desc/asc) for additional randomization if needed
// const q = query(productsRef,where('visible','==',true),orderBy('sales', 'desc'), orderBy('rating', 'desc'), limit(15))
   
// onMounted(async()=>{
//   const p = await getDocs(q)
//   let allTopTags = []
//   for(let i=0; i<p.size; i++){
//     allTopTags = union(allTopTags, p.docs[i].get('tags'))
//   }
//   allTopTags = uniq(allTopTags)
//   allTopTags = shuffle(allTopTags)
//   if(allTopTags<6){
//     console.log("Not enough tags found :(")
//   }
//   for(let i=0;i<6;i++){
//     tabs.value.push(allTopTags[i])
//   }
// })

const makeMeAdmin = async() =>{
  if(useCurrentUser().value){
    if(useRuntimeConfig().public.WTFAMI=="DEV"){
      await $fetch('/api/userRole', {method:'POST', body:{uid:useCurrentUser().value.uid, roles:['editor','service','admin','super-admin']}})
    } else {
      return {error:'Fuck off'}
    }
  }
}

</script>

<style scoped>

.header_sitetitle {
  font-family: 'Pacifico';
/* text-shadow: 0 5px 9px rgb(var(--v-theme-secondary-lighten-2)), -2px -2px 2px #fff, 0px 0px 3px rgb(var(--v-theme-secondary)); */
  /* font-weight: 200; */
  font-weight: lighter;
  color: oklch(var(--nc));
  text-shadow: 
        -1px -2px 0 oklch(var(--n)),  
        -2px -1px 0 oklch(var(--n)),  
        1px -2px 0 oklch(var(--n)),
        2px -1px 0 oklch(var(--n)),
        -1px 2px 0 oklch(var(--n)),
        -2px 1px 0 oklch(var(--n)),
        1px 2px 0 oklch(var(--n)),
        2px 1px 0 oklch(var(--n));
}

@keyframes color {
  0%   { background: rgb(var(--v-theme-primary-lighten-1)) }
  50%  { background: rgb(var(--v-theme-primary)); }
  100% { background: rgb(var(--v-theme-primary-lighten-1)) }
}

.backimg {
  background: #ffffff url(https://cdn.midjourney.com/285abd20-6660-49fc-a8b4-90ca9a7909ef/0_3.webp) center center/auto repeat content-box;
}

</style>
